var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "animated fadeIn"
  }, [_c('b-card', [_c('b-row', [_c('b-col', [_c('filtering-panel', {
    ref: "filteringPanel",
    attrs: {
      "mode": "client",
      "dataset-name": "time-tracker-my-time",
      "hide-search-button": true,
      "loaded": _vm.filteringPanel.loaded,
      "show-labels": false,
      "compact": false,
      "hide-custom-filters": true,
      "filters": _vm.filteringPanel.filters
    },
    on: {
      "change": _vm.onFilteringPanelChange,
      "search": _vm.onFilteringPanelSearch,
      "reset": _vm.onFilteringPanelReset,
      "loaded": _vm.onFilteringPanelLoad
    }
  })], 1)], 1), _c('hr'), _c('b-button-group', {
    staticClass: "mb-2 ml-2"
  }, [_c('b-button', {
    attrs: {
      "variant": "outline-dark",
      "title": "Add time"
    },
    on: {
      "click": function click($event) {
        return _vm.addTimeDialog(_vm.$constants.TRACK_TIME_ACTIONS.ADD_TIME);
      }
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": "calendar-plus"
    }
  })], 1)], 1), _c('v-client-table', {
    ref: "dataTable",
    attrs: {
      "name": "client_table_my_time",
      "data": _vm.dataTable.dataSet,
      "options": _vm.dataTable.options,
      "columns": _vm.dataTable.columns
    },
    scopedSlots: _vm._u([_vm._l(_vm.dataTable.columns, function (column, index) {
      return {
        key: column,
        fn: function fn(_ref) {
          var row = _ref.row;
          return [!row[column].disabled ? _c('b-card', {
            key: "bct-".concat(index),
            ref: "cell-".concat(row[column].id),
            refInFor: true,
            staticClass: "text-center",
            attrs: {
              "text-variant": row[column].selected ? 'white' : 'black',
              "bg-variant": row[column].selected ? 'dark' : row[column].readonly ? 'secondary' : ''
            },
            on: {
              "click": function click($event) {
                return _vm.onCellClick(column, row);
              }
            }
          }, [_c('b-card-text', [_vm._v(_vm._s(row[column].text) + " ")]), row[column].status === _vm.$constants.DAY_STATUS.TRACKING ? _c('font-awesome-icon', {
            staticStyle: {
              "color": "lime"
            },
            attrs: {
              "icon": "clock"
            }
          }) : _vm._e(), row[column].status === _vm.$constants.DAY_STATUS.COMPLETED ? _c('font-awesome-icon', {
            attrs: {
              "icon": "check"
            }
          }) : _vm._e(), row[column].status === _vm.$constants.DAY_STATUS.FORGOTTEN ? _c('font-awesome-icon', {
            attrs: {
              "icon": "check"
            }
          }) : _vm._e(), row[column].status === _vm.$constants.DAY_STATUS.NOTCOMPLETED ? _c('font-awesome-icon', {
            staticStyle: {
              "color": "red"
            },
            attrs: {
              "icon": "exclamation-circle"
            }
          }) : _vm._e(), row[column].status === _vm.$constants.DAY_STATUS.SICK ? _c('font-awesome-icon', {
            attrs: {
              "icon": "temperature-high"
            }
          }) : _vm._e(), row[column].status && (row[column].status === _vm.$constants.DAY_STATUS.VACATION || row[column].status === _vm.$constants.DAY_STATUS.PLA) ? _c('font-awesome-icon', {
            staticStyle: {
              "color": "gray"
            },
            attrs: {
              "icon": "plane"
            }
          }) : _vm._e(), row[column].status && (row[column].status === _vm.$constants.DAY_STATUS.VACATION_APPROVED || row[column].status === _vm.$constants.DAY_STATUS.PLA_APPROVED) ? _c('font-awesome-icon', {
            staticStyle: {
              "color": "#4dbd74"
            },
            attrs: {
              "icon": "plane"
            }
          }) : _vm._e(), row[column].status && (row[column].status === _vm.$constants.DAY_STATUS.VACATION_DECLINED || row[column].status === _vm.$constants.DAY_STATUS.PLA_DECLINED) ? _c('font-awesome-icon', {
            staticStyle: {
              "color": "red"
            },
            attrs: {
              "icon": "plane"
            }
          }) : _vm._e(), row[column].status === _vm.$constants.DAY_STATUS.HOLIDAY ? _c('font-awesome-icon', {
            attrs: {
              "icon": "home"
            }
          }) : _vm._e()], 1) : _vm._e()];
        }
      };
    })], null, true)
  }, [_c('span', {
    attrs: {
      "slot": "child_row"
    },
    slot: "child_row"
  }, [_c('b-container', [_c('b-row', {
    staticClass: "justify-content-md-center"
  }, [_c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('b-row', {
    staticClass: "justify-content-md-left"
  }, [_c('b-col', {
    attrs: {
      "lg": "2"
    }
  }, [!_vm.selectedDay().readonly ? _c('b-button-group', {
    staticClass: "mb-2"
  }, [_vm.selectedDay().status === _vm.$constants.DAY_STATUS.NOTSTARTED || _vm.selectedDay().status === _vm.$constants.DAY_STATUS.VACATION_DECLINED ? _c('b-button', {
    attrs: {
      "variant": "outline-dark",
      "title": "Add time"
    },
    on: {
      "click": function click($event) {
        _vm.addTimeDialog(_vm.$constants.TRACK_TIME_ACTIONS.ADD_TIME, _vm.selectedDay());
      }
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": "calendar-plus"
    }
  })], 1) : _vm._e(), _vm.selectedDay().status === _vm.$constants.DAY_STATUS.NOTCOMPLETED || _vm.selectedDay().status === _vm.$constants.DAY_STATUS.TRACKING ? _c('b-button', {
    attrs: {
      "variant": "outline-dark",
      "title": "Clock Out"
    },
    on: {
      "click": function click($event) {
        _vm.clockTimeDialog(_vm.$constants.TRACK_TIME_ACTIONS.CLOCK_OUT, _vm.selectedDay());
      }
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": "sign-out-alt"
    }
  })], 1) : _vm._e(), _vm.selectedDay().status === _vm.$constants.DAY_STATUS.FORGOTTEN || _vm.selectedDay().status === _vm.$constants.DAY_STATUS.NOTCOMPLETED || _vm.selectedDay().status === _vm.$constants.DAY_STATUS.COMPLETED || _vm.selectedDay().status === _vm.$constants.DAY_STATUS.TRACKING ? _c('b-button', {
    attrs: {
      "variant": "outline-dark",
      "title": "Lunch"
    },
    on: {
      "click": function click($event) {
        _vm.clockTimeDialog(_vm.$constants.TRACK_TIME_ACTIONS.LUNCH, _vm.selectedDay());
      }
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": "utensils"
    }
  })], 1) : _vm._e(), _c('b-button', {
    attrs: {
      "title": "Add reimbursements",
      "variant": "outline-dark"
    },
    on: {
      "click": function click($event) {
        _vm.addReimbursements(_vm.selectedDay());
      }
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": "dollar-sign"
    }
  })], 1), _vm.selectedDay().status && _vm.selectedDay().status !== _vm.$constants.DAY_STATUS.NOTSTARTED && _vm.selectedDay().status !== _vm.$constants.DAY_STATUS.HOLIDAY && _vm.selectedDay().status !== _vm.$constants.DAY_STATUS.VACATION && _vm.selectedDay().status !== _vm.$constants.DAY_STATUS.VACATION_APPROVED && _vm.selectedDay().status !== _vm.$constants.DAY_STATUS.VACATION_DECLINED ? _c('b-button', {
    attrs: {
      "variant": "danger",
      "title": "Delete time log"
    },
    on: {
      "click": function click($event) {
        _vm.deleteTimeDialog(_vm.selectedDay());
      }
    }
  }, [!_vm.dataTable.isLoading ? _c('font-awesome-icon', {
    attrs: {
      "icon": "ban"
    }
  }) : _vm._e(), _vm.dataTable.isLoading ? _c('b-spinner', {
    attrs: {
      "small": "",
      "type": "grow"
    }
  }) : _vm._e()], 1) : _vm._e()], 1) : _vm._e()], 1)], 1), _c('b-table', {
    attrs: {
      "fields": _vm.dataTable.detailsColumns,
      "items": _vm.selectedDay().details
    }
  }), _c('b-row', {
    staticClass: "justify-content-md-left"
  }, [_c('b-col', {
    attrs: {
      "lg": "8"
    }
  }, [_c('b-container', [_vm.selectedDay().totalWorked ? _c('b-row', [_c('b-col', {
    staticClass: "font-weight-bold"
  }, [_vm._v("Total worked:")]), _c('b-col', [_c('h5', [_c('b-badge', {
    attrs: {
      "variant": _vm.selectedDay().totalWorked.includes('-') ? 'danger' : ''
    }
  }, [_vm._v(_vm._s(_vm.selectedDay().totalWorked))])], 1)])], 1) : _vm._e(), _vm.selectedDay().totalLunch ? _c('b-row', [_c('b-col', {
    staticClass: "font-weight-bold"
  }, [_vm._v("Total lunch:")]), _c('b-col', [_vm._v(_vm._s(_vm.selectedDay().totalLunch))])], 1) : _vm._e()], 1)], 1)], 1)], 1)], 1)], 1)], 1)])], 1), _c('b-card', {
    attrs: {
      "bg-variant": "light"
    }
  }, [_c('b-card-text', [_c('h5', [_vm._v("Total worked hr: " + _vm._s(_vm.totals.workedHours))]), _c('h5', [_vm._v("Total lunch hr: " + _vm._s(_vm.totals.lunchHours))])])], 1), _c('clock-time-dialog', {
    ref: "clockTimeDialog",
    on: {
      "save": _vm.onDialogSave
    }
  }), _c('add-time-dialog', {
    ref: "addTimeDialog",
    on: {
      "save": _vm.onDialogSave
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }