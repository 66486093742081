<template>
  <div class="animated fadeIn">
    <b-card>
      <b-row>
        <b-col>
          <filtering-panel
            ref="filteringPanel"
            mode="client"
            dataset-name="time-tracker-my-time"
            :hide-search-button="true"
            :loaded="filteringPanel.loaded"
            :show-labels="false"
            :compact="false"
            :hide-custom-filters="true"
            :filters="filteringPanel.filters"
            @change="onFilteringPanelChange"
            @search="onFilteringPanelSearch"
            @reset="onFilteringPanelReset"
            @loaded="onFilteringPanelLoad"
          />
        </b-col>
      </b-row>
      <hr />

      <b-button-group class="mb-2 ml-2">
        <b-button
          variant="outline-dark"
          @click="addTimeDialog($constants.TRACK_TIME_ACTIONS.ADD_TIME)"
          title="Add time"
        >
          <font-awesome-icon icon="calendar-plus" />
        </b-button>
      </b-button-group>

      <v-client-table
        ref="dataTable"
        name="client_table_my_time"
        :data="dataTable.dataSet"
        :options="dataTable.options"
        :columns="dataTable.columns"
      >
        <template
          v-for="(column, index) in dataTable.columns"
          :slot="column"
          slot-scope="{ row }"
        >
          <b-card
            class="text-center"
            :key="`bct-${index}`"
            v-if="!row[column].disabled"
            :ref="`cell-${row[column].id}`"
            @click="onCellClick(column, row)"
            :text-variant="row[column].selected ? 'white' : 'black'"
            :bg-variant="
              row[column].selected
                ? 'dark'
                : row[column].readonly
                ? 'secondary'
                : ''
            "
          >
            <b-card-text>{{ row[column].text }} </b-card-text>
            <font-awesome-icon
              v-if="row[column].status === $constants.DAY_STATUS.TRACKING"
              icon="clock"
              style="color: lime"
            />
            <font-awesome-icon
              v-if="row[column].status === $constants.DAY_STATUS.COMPLETED"
              icon="check"
            />
            <font-awesome-icon
              v-if="row[column].status === $constants.DAY_STATUS.FORGOTTEN"
              icon="check"
            />
            <font-awesome-icon
              v-if="row[column].status === $constants.DAY_STATUS.NOTCOMPLETED"
              icon="exclamation-circle"
              style="color: red"
            />
            <font-awesome-icon
              v-if="row[column].status === $constants.DAY_STATUS.SICK"
              icon="temperature-high"
            />
            <font-awesome-icon
              v-if="
                row[column].status &&
                  (row[column].status === $constants.DAY_STATUS.VACATION ||
                    row[column].status === $constants.DAY_STATUS.PLA)
              "
              icon="plane"
              style="color: gray"
            />
            <font-awesome-icon
              v-if="
                row[column].status &&
                  (row[column].status ===
                    $constants.DAY_STATUS.VACATION_APPROVED ||
                    row[column].status === $constants.DAY_STATUS.PLA_APPROVED)
              "
              icon="plane"
              style="color: #4dbd74"
            />
            <font-awesome-icon
              v-if="
                row[column].status &&
                  (row[column].status ===
                    $constants.DAY_STATUS.VACATION_DECLINED ||
                    row[column].status === $constants.DAY_STATUS.PLA_DECLINED)
              "
              icon="plane"
              style="color: red"
            />
            <font-awesome-icon
              v-if="row[column].status === $constants.DAY_STATUS.HOLIDAY"
              icon="home"
            />
          </b-card>
        </template>
        <span slot="child_row">
          <b-container>
            <b-row class="justify-content-md-center">
              <b-col lg="6">
                <b-row class="justify-content-md-left">
                  <b-col lg="2">
                    <b-button-group class="mb-2" v-if="!selectedDay().readonly">
                      <b-button
                        v-if="
                          selectedDay().status ===
                            $constants.DAY_STATUS.NOTSTARTED ||
                            selectedDay().status ===
                              $constants.DAY_STATUS.VACATION_DECLINED
                        "
                        variant="outline-dark"
                        @click="
                          addTimeDialog(
                            $constants.TRACK_TIME_ACTIONS.ADD_TIME,
                            selectedDay()
                          )
                        "
                        title="Add time"
                      >
                        <font-awesome-icon icon="calendar-plus" />
                      </b-button>
                      <b-button
                        v-if="
                          selectedDay().status ===
                            $constants.DAY_STATUS.NOTCOMPLETED ||
                            selectedDay().status ===
                              $constants.DAY_STATUS.TRACKING
                        "
                        variant="outline-dark"
                        @click="
                          clockTimeDialog(
                            $constants.TRACK_TIME_ACTIONS.CLOCK_OUT,
                            selectedDay()
                          )
                        "
                        title="Clock Out"
                      >
                        <font-awesome-icon icon="sign-out-alt" />
                      </b-button>
                      <b-button
                        v-if="
                          selectedDay().status ===
                            $constants.DAY_STATUS.FORGOTTEN ||
                            selectedDay().status ===
                              $constants.DAY_STATUS.NOTCOMPLETED ||
                            selectedDay().status ===
                              $constants.DAY_STATUS.COMPLETED ||
                            selectedDay().status ===
                              $constants.DAY_STATUS.TRACKING
                        "
                        variant="outline-dark"
                        @click="
                          clockTimeDialog(
                            $constants.TRACK_TIME_ACTIONS.LUNCH,
                            selectedDay()
                          )
                        "
                        title="Lunch"
                      >
                        <font-awesome-icon icon="utensils" />
                      </b-button>
                      <b-button
                        title="Add reimbursements"
                        variant="outline-dark"
                        @click="addReimbursements(selectedDay())"
                      >
                        <font-awesome-icon icon="dollar-sign" />
                      </b-button>

                      <b-button
                        v-if="
                          selectedDay().status &&
                            selectedDay().status !==
                              $constants.DAY_STATUS.NOTSTARTED &&
                            selectedDay().status !==
                              $constants.DAY_STATUS.HOLIDAY &&
                            selectedDay().status !==
                              $constants.DAY_STATUS.VACATION &&
                            selectedDay().status !==
                              $constants.DAY_STATUS.VACATION_APPROVED &&
                            selectedDay().status !==
                              $constants.DAY_STATUS.VACATION_DECLINED
                        "
                        @click="deleteTimeDialog(selectedDay())"
                        variant="danger"
                        title="Delete time log"
                      >
                        <font-awesome-icon
                          icon="ban"
                          v-if="!dataTable.isLoading"
                        />
                        <b-spinner
                          v-if="dataTable.isLoading"
                          small
                          type="grow"
                        />
                      </b-button>
                    </b-button-group>
                  </b-col>
                </b-row>
                <b-table
                  :fields="dataTable.detailsColumns"
                  :items="selectedDay().details"
                />

                <b-row class="justify-content-md-left">
                  <b-col lg="8">
                    <b-container>
                      <b-row v-if="selectedDay().totalWorked">
                        <b-col class="font-weight-bold">Total worked:</b-col>
                        <b-col>
                          <h5>
                            <b-badge
                              :variant="
                                selectedDay().totalWorked.includes('-')
                                  ? 'danger'
                                  : ''
                              "
                              >{{ selectedDay().totalWorked }}</b-badge
                            >
                          </h5>
                        </b-col>
                      </b-row>
                      <b-row v-if="selectedDay().totalLunch">
                        <b-col class="font-weight-bold">Total lunch:</b-col>
                        <b-col>{{ selectedDay().totalLunch }}</b-col>
                      </b-row>
                    </b-container>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-container>
        </span>
      </v-client-table>
    </b-card>

    <b-card bg-variant="light">
      <b-card-text>
        <h5>Total worked hr: {{ totals.workedHours }}</h5>
        <h5>Total lunch hr: {{ totals.lunchHours }}</h5>
      </b-card-text>
    </b-card>

    <clock-time-dialog ref="clockTimeDialog" @save="onDialogSave" />
    <add-time-dialog ref="addTimeDialog" @save="onDialogSave" />
  </div>
</template>

<script>
import moment from 'moment'

import FilteringPanel from '@/components/FilteringPanel'
import ClockTimeDialog from '@/components/ClockTimeDialog'
import AddTimeDialog from '@/components/AddTimeDialog'

import { mapState, mapActions } from 'vuex'

import Vue from 'vue'

export default {
  name: 'MyTime',
  components: {
    FilteringPanel,
    ClockTimeDialog,
    AddTimeDialog
  },
  data: function () {
    return {
      isLoading: false,
      rawData: {},
      filteringPanel: {
        loaded: true,
        selected: {},
        filters: [
          {
            type: 'daterange',
            dataType: 'datetime',
            defaultRange: 'This month',
            title: 'Period',
            name: 'period'
          }
        ]
      },
      dataTable: {
        isLoading: false,
        options: {
          uniqueKey: 'id',
          childRowTogglerFirst: false,
          showChildRowToggler: false,
          filterByColumn: false,
          filterable: [],
          perPage: 50,
          perPageValues: [],
          saveState: true,
          storage: 'local',
          skin: 'table'
        },
        columns: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        dataSet: [],
        selectedCell: null,
        onRowClick: function () {},
        totalRecords: 0,
        detailsColumns: [
          {
            key: 'action',
            label: 'Action'
          },
          {
            key: 'track_date',
            label: 'Date'
          },
          {
            key: 'time1',
            label: 'From'
          },
          {
            key: 'time2',
            label: 'To'
          },
          {
            key: 'location',
            label: 'Location'
          },
          {
            key: 'notes',
            label: 'Notes'
          },
          {
            key: 'status',
            label: 'Status'
          }
        ]
      },
      totals: {
        workedHours: 0,
        lunchHours: 0
      }
    }
  },
  computed: {
    ...mapState({
      profile: state => state.profile,
      timeTracker: state => state.timeTracker
    })
  },
  created () {},
  mounted () {
    //        this.getData()
    //        this.createCalendar()
  },
  methods: {
    ...mapActions('timeTracker', ['deleteTime']),
    onFilteringPanelChange (e) {
      this.filteringPanel.selected = e
      this.createCalendar()
      this.getData()
    },
    onFilteringPanelSearch (e) {
      this.filteringPanel.selected = e

      this.getData()
    },
    onFilteringPanelReset () {},
    onFilteringPanelLoad () {
      this.createCalendar()
      this.getData()
    },
    addReimbursements (e) {
      this.$router.push({
        name: 'Reimbursement submission',
        params: {
          action: 'create',
          date: e.day.format('YYYY-MM-DD')
        }
      })
    },
    selectedDay () {
      if (!this.dataTable.selectedCell) return this.getCalendarDay(moment())

      let cell = this.dataTable.dataSet[this.dataTable.selectedCell.row][
        this.dataTable.selectedCell.col
      ]
      //console.log("selectedDay.cell", JSON.stringify(cell));
      return cell
    },
    onCellClick: async function (_cidx, _row) {
      let oldSelected = this.dataTable.selectedCell
      let newSelected = {
        col: _row[_cidx].col,
        row: _row[_cidx].row
      }

      if (oldSelected)
        this.dataTable.dataSet[oldSelected.row][
          oldSelected.col
        ].selected = false

      this.dataTable.dataSet[newSelected.row][newSelected.col].selected = true

      this.dataTable.selectedCell = newSelected

      this.$refs.dataTable.$refs.table.openChildRows.forEach(id => {
        if (id !== _row.id) {
          this.$refs.dataTable.toggleChildRow(id)
        }
      })

      if (
        !oldSelected ||
        (oldSelected && oldSelected.row !== newSelected.row) ||
        (oldSelected.row === newSelected.row &&
          oldSelected.col === newSelected.col)
      )
        this.$refs.dataTable.toggleChildRow(_row.id)
    },
    getData: function () {
      let data = this.$refs.filteringPanel
        ? this.$refs.filteringPanel.selected
        : {}

      data.user_id = this.profile.data.id

      this.$api
        .post('timetracker/mytime', data)
        .then(response => {
          // console.log('getData', response)
          this.updateCalendar(response)
        })
        .catch(error => {
          console.log(error)
        })
    },
    createCalendar () {
      let lastPayrollPeriod = this.$helpers
        .getBiWeeklyDatesArray(
          moment()
            .clone()
            .subtract(1, 'month'),
          moment(),
          false
        )
        .reverse()
        .find(d => moment(d.endDate).isBefore(moment()))
      let lastPayrollDate = moment(lastPayrollPeriod.endDate)
        .clone()
        .isoWeekday(5)
        .format('YYYY-MM-DD')

      let start = this.$refs.filteringPanel.selected['period'].startDate
      let end = this.$refs.filteringPanel.selected['period'].endDate

      let sundayStart = moment(start).startOf('week')
      let saturdayEnd = moment(end).endOf('week')

      let day = moment(sundayStart)

      this.dataTable.dataSet = []
      let i = 0,
        j = 0

      while (day <= saturdayEnd) {
        let row = {
          id: this.$helpers.uuidv4()
        }

        for (let column of this.dataTable.columns) {
          let _day = day.clone()

          row[column] = {
            id: this.$helpers.uuidv4(),
            row: j,
            col: column,
            day: moment(_day).add(i, 'day'),
            text: moment(_day)
              .add(i, 'day')
              .format('MMM, DD'),
            disabled:
              moment(_day).add(i, 'day') < moment(start) ||
              moment(_day).add(i, 'day') > moment(end),
            selected: false,
            status: undefined,
            details: [],
            //readonly: (moment.duration(moment().diff(moment(_day).add(i, 'day'))).asDays() > 14),
            //#220 make times and dates inline editable for user until payroll has been run
            readonly: moment(_day)
              .add(i, 'day')
              .isSameOrBefore(moment(lastPayrollDate)),
            approved: undefined
          }

          i++
        }

        j++

        this.dataTable.dataSet.push(row)

        Vue.set(this.dataTable.dataSet, row)

        if (row['Sat'].day >= saturdayEnd) break

        moment(day).add(1, 'week')
      }

      this.dataTable.selectedCell = null
    },
    totalTime (a, b) {
      let start = moment(a)
      let end = moment(b)
      let diff = end.diff(start)
      let f = moment.utc(diff).format('HH:mm:ss')

      return f
    },
    setCalendarDay (_cell) {
      this.dataTable.dataSet[_cell.row][_cell.col] = _cell
    },
    getCalendarDay (_date) {
      for (let row of this.dataTable.dataSet) {
        for (let col of this.dataTable.columns) {
          if (row[col].day.isSame(_date, 'day')) return row[col]
        }
      }

      return false
    },
    getDayStatus (cell) {
      let status = undefined

      if (!cell.details || cell.details.length === 0) {
        return this.$constants.DAY_STATUS.NOTSTARTED
      }

      let clockInCnt = cell.details.filter(i => i.action === 'Clock in').length
      let clockOutCnt = cell.details.filter(i => i.action === 'Clock out')
        .length
      let sickCnt = cell.details.filter(i => i.action === 'Sick day').length
      let vacationCnt = cell.details.filter(i => i.action === 'Vacation').length
      let vacationOnReview = cell.details.filter(
        i => i.action === 'Vacation' && i.approved === ''
      ).length
      let vacationApproved = cell.details.filter(
        i => i.action === 'Vacation' && i.approved == '1'
      ).length
      let vacationDeclined = cell.details.filter(
        i => i.action === 'Vacation' && i.approved == '0'
      ).length
      let forgottenCnt = cell.details.filter(i => i.action === 'Forgotten day')
        .length
      let holidayCnt = cell.details.filter(i => i.action === 'Holiday').length

      if (holidayCnt > 0) {
        return this.$constants.DAY_STATUS.HOLIDAY
      }
      if (forgottenCnt > 0) {
        return this.$constants.DAY_STATUS.FORGOTTEN
      }
      if (sickCnt > 0) {
        return this.$constants.DAY_STATUS.SICK
      }

      if (vacationCnt > 0 && vacationOnReview > 0) {
        return this.$constants.DAY_STATUS.VACATION
      }
      if (vacationCnt > 0 && vacationApproved > 0) {
        return this.$constants.DAY_STATUS.VACATION_APPROVED
      }
      if (vacationCnt > 0 && vacationApproved === 0 && vacationDeclined > 0) {
        return this.$constants.DAY_STATUS.VACATION_DECLINED
      }

      if (clockInCnt === 0) {
        return this.$constants.DAY_STATUS.NOTSTARTED
      }
      if (clockInCnt !== clockOutCnt) {
        return this.$constants.DAY_STATUS.NOTCOMPLETED
      }
      if (clockInCnt > 0 && clockInCnt === clockOutCnt) {
        return this.$constants.DAY_STATUS.COMPLETED
      }
      if (
        moment().isSame(cell.day, 'day') &&
        status === this.$constants.DAY_STATUS.NOTCOMPLETED
      ) {
        return this.$constants.DAY_STATUS.TRACKING
      }
    },
    getOpenTrackId (cell) {
      let result = undefined

      if (!cell.details) return false

      let clocks = cell.details.filter(i => i.action === 'Clock in')

      if (clocks.length > 0) result = clocks[clocks.length - 1].trackid

      if (cell.status === this.$constants.DAY_STATUS.FORGOTTEN) {
        result = cell.details.find(d => d.action === 'Forgotten day').trackid
      }

      return result
    },
    cleanUpCalendar () {
      for (let row of this.dataTable.dataSet) {
        for (let col of this.dataTable.columns) {
          let cell = row[col]

          cell.details = []
          cell.status = undefined
          cell.openTrackId = undefined
        }
      }

      this.totals.workedHours = 0
      this.totals.lunchHours = 0
    },
    calcDayTotals (data) {
      let clockIn = 0,
        clockOut = 0,
        totalWorkedMinutes = 0,
        totalLunch = 0

      data.details.forEach(cell => {
        if (cell.action === 'Clock in') {
          clockIn = cell.time_stamp
        }

        if (cell.action === 'Lunch') {
          totalLunch += moment.duration(
            moment(cell.time2, 'HH:mm:ss').diff(
              moment(cell.time1, 'HH:mm:ss'),
              'minutes',
              true
            )
          )
        }

        if (cell.action === 'Clock out' && clockIn !== 0) {
          clockOut = cell.time_stamp

          totalWorkedMinutes += moment.duration(
            moment(clockOut).diff(moment(clockIn), 'minutes', true)
          )

          clockIn = 0
          clockOut = 0
        }

        if (cell.action === 'Forgotten day') {
          totalWorkedMinutes += moment.duration(
            moment(`${cell.date2} ${cell.time2}`).diff(
              moment(`${cell.date1} ${cell.time1}`),
              'minutes',
              true
            )
          )
        }
      })

      totalWorkedMinutes = totalWorkedMinutes - totalLunch

      return {
        totalLunchFloat: totalLunch / 60,
        totalWorkedFloat: totalWorkedMinutes / 60,
        totalLunch:
          totalLunch > 0
            ? moment
                .utc()
                .hours(totalLunch / 60)
                .minutes(totalLunch % 60)
                .format('HH:mm')
            : '',
        totalWorked:
          totalWorkedMinutes > 0
            ? moment
                .utc()
                .hours(totalWorkedMinutes / 60)
                .minutes(totalWorkedMinutes % 60)
                .format('HH:mm')
            : totalWorkedMinutes < 0
            ? '-' +
              moment
                .utc()
                .hours(Math.abs(totalWorkedMinutes) / 60)
                .minutes(Math.abs(totalWorkedMinutes) % 60)
                .format('HH:mm')
            : ''
      }
    },
    updateCalendar (data) {
      //console.log("updateCalendar");
      this.cleanUpCalendar()

      data.forEach(item => {
        let cell = this.getCalendarDay(item.track_date)

        if (cell) {
          if (!cell.details) cell.details = []

          if (item.action === 'Vacation') {
            if (item.approved === '') item.status = 'On review'
            if (item.approved == '1') item.status = 'Approved'
            if (item.approved == '0') item.status = 'Declined'
          }

          cell.details.push(item)
        }
      })

      for (let row of this.dataTable.dataSet) {
        for (let col of this.dataTable.columns) {
          let cell = row[col]

          cell.status = this.getDayStatus(cell)

          cell.openTrackId = this.getOpenTrackId(cell)

          let totals = this.calcDayTotals(cell)

          cell.totalWorked = totals.totalWorked
          cell.totalLunch = totals.totalLunch

          this.totals.workedHours += totals.totalWorkedFloat
          this.totals.lunchHours += totals.totalLunchFloat
        }
      }

      this.totals.workedHours = this.totals.workedHours.toFixed(2)
      this.totals.lunchHours = this.totals.lunchHours.toFixed(2)
    },
    addTimeDialog (action, cell) {
      this.$refs.addTimeDialog.show(action, cell)
    },

    clockTimeDialog (action, cell) {
      this.$refs.clockTimeDialog.show(action, cell)
    },
    onDialogSave () {
      this.getData()
    },
    deleteTimeDialog: async function (cell) {
      let confirm = await this.$form.showConfirmation(
        `Time log for ${cell.day.format(
          'YYYY-MM-DD'
        )} will be deleted. Do you want to proceed?`
      )

      if (!confirm) return

      let self = this
      let payload = {
        user_id: this.profile.data.id,
        date: cell.day.format('YYYY-MM-DD')
      }

      self.dataTable.isLoading = true

      this.deleteTime(payload)
        .then(response => {
          self.dataTable.isLoading = false

          self.$form.makeToastInfo(response.message)

          self.getData()
        })
        .catch(error => {
          self.isLoading = false

          self.$form.makeToastError(error.message)
        })
    }
  },
  watch: {
    'timeTracker.data': function () {
      this.getData()
    }
  }
}
</script>

<style lang="scss" scoped>
.card {
  min-height: 80px;
  min-width: 80px;
  margin-bottom: 0rem;
}
</style>
